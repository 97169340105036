<template>
  <loader v-bind="{ loading }" text="Loading Members">
    <columns>
      <column>
        <form>
          <text-input 
            placeholder="Search for team members"
            classes="is-rounded has-addons">
            <template #right>
              <submit-button class="is-rounded">Search</submit-button>
            </template>  
          </text-input>
        </form>
      </column>
      <column class="is-narrow">
        <div class="buttons">
          <dropdown-wrapper class="navbar-item" position="bottom-end">
            <action-button
              class="is-small is-rounded"
              slot="trigger">
              Add User
            </action-button>

            <dropdown-item @click="addUser(user)" v-for="user in availableUsersToAdd" :key="user.id">
              <icon class="mr" icon="plus" />
              <span>{{ user.full_name }}</span>
            </dropdown-item>
          
            <dropdown-item v-if="!availableUsersToAdd.length">
              <span>There are no users available <br> to add to this team. Try creating a new user.</span>
            </dropdown-item>

          </dropdown-wrapper>
          <action-button @click="openNewUser" class="is-small is-rounded" left-icon="plus">
            New User
          </action-button>
        </div>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="user in users.data" :key="user.id">
            <columns>
              <column>
                <router-link :to="{
                    name: 'user-manager',
                    params: {
                      user: user.uuid
                    }
                  }">
                  {{ user.full_name }}
                </router-link>
                <p class="subtitle is-6 has-text-grey">
                  Active {{ user.updated_at | asCalendar }}
                  <span v-if="isTeamLead(user.id)" class="tag is-warning is-small">Team Lead</span>
                </p>
              </column>
              <column class="is-narrow is-flex is-align-items-center">
                <action-button @click="removeUser(user)" class="is-small is-rounded is-danger">Remove</action-button>
              </column>
            </columns>
          </div>
        </div>
        <pager :pageable="users" context="Team Member" jump-controls @nav="goToPage" />
      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import { mapGetters } from 'vuex'
import { differenceBy } from 'lodash'
import { createTeamUser } from '@/modals'

export default {

  data: () => ({
    loading: true,
    users: {
      data: []
    }
  }),

  created() {
    this.loadUsers()
  },

  methods: {
    loadUsers() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.users = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    async removeUser(user) {

      if(this.isTeamLead(user.id)) {
        return this.$toast.error('You cannot remove the team leader')
      }

      if(await this.$confirm({
        title: `Remove Team Member`,
        message: `Are you sure you want to remove ${user.full_name} from ${this.team.name}`,
        confirmClass: 'is-danger is-rounded',
        cancelClass: 'is-outlined is-rounded is-small',
        confirmText: `Remove ${user.name}`
      })) {
        await this.$store.dispatch('team/removeTeamMember', user)
        const index = this.users.data.indexOf(user)
        this.users.data.splice(index, 1)
      }
    },
    async addUser(user) {
      await this.$store.dispatch('team/addTeamMember', user)
      this.users.data.push(user)
    },
    openNewUser() {
      createTeamUser(this.team.branch).then(user => {
        if(user) {
          this.$store.dispatch('team/addTeamMember', user)
          this.users.data.push(user)
        }
      })
    },
    isTeamLead(userId) {
      return this.team.team_lead_id === userId
    }
  },

  computed: {
    ...mapGetters('team', [
      'team'
    ]),
    teamUsers() {
      return this.team.users
    },
    branchUsers() {
      return this.team.branch.users
    },
    availableUsersToAdd() {
      return differenceBy(this.branchUsers, this.teamUsers, 'id')
    }
  },

  watch: {
    '$route': 'loadUsers'
  }

}
</script>